import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import usePageTracking from './usePageTracking';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ComingSoon from './Graphic/comingSoon.png';
import Logo from './Graphic/logo.png';
import PrivacyPolicy from './PrivacyPolicy';
import Energy from './Energy';
import Broadband from './Broadband';
import Careers from './Careers';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const TRACKING_ID = "G-B8K8102CNC";

const App = () => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname, 
      title: "App.js"});
  }, []);

  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        {/* Navbar */}
        <header className="bg-white shadow-lg">
          <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center py-4">
              {/* Logo on the left */}
              <div className="flex-shrink-0">
                <img src={Logo} alt="Comparison Guru Logo" className="h-10 w-auto sm:h-12" />
              </div>
              {/* Desktop Navigation Links */}
              <div className="hidden md:flex space-x-8 text-light-purple items-center">
                <Link to="/" className="flex items-center text-lg font-medium hover:text-light-purple-h transition duration-300">
                  <i className="fas fa-home mr-2"></i> Home
                </Link>
                <Link to="/energy" className="flex items-center text-lg font-medium hover:text-light-purple-h transition duration-300">
                  <i className="fas fa-bolt mr-2"></i> Energy
                </Link>
                <Link to="/broadband" className="flex items-center text-lg font-medium hover:text-light-purple-h transition duration-300">
                  <i className="fas fa-wifi mr-2"></i> Broadband
                </Link>
                <Link to="/careers" className="flex items-center text-lg font-medium hover:text-light-purple-h transition duration-300">
                  <i className="fas fa-briefcase mr-2"></i> Careers
                </Link>
                <Link to="/privacy-policy" className="flex items-center text-lg font-medium hover:text-light-purple-h transition duration-300">
                  <i className="fas fa-shield-alt mr-2"></i> Privacy Policy
                </Link>
              </div>

              {/* Mobile Menu Button */}
              <div className="md:hidden flex items-center">
                <button
                  className="text-light-purple focus:outline-none"
                  onClick={() => document.getElementById('mobile-menu').classList.toggle('hidden')}
                >
                  <i className="fas fa-bars text-xl"></i>
                </button>
              </div>
            </div>
          </nav>

          {/* Mobile Navigation Links */}
          <div id="mobile-menu" className="md:hidden hidden bg-white shadow-lg">
            <div className="flex flex-col p-4 space-y-4 text-light-purple">
              <Link to="/" className="text-lg font-medium hover:text-light-purple-h transition duration-300">
                Home
              </Link>
              <Link to="/energy" className="text-lg font-medium hover:text-light-purple-h transition duration-300">
                Energy
              </Link>
              <Link to="/broadband" className="text-lg font-medium hover:text-light-purple-h transition duration-300">
                Broadband
              </Link>
              <Link to="/careers" className="text-lg font-medium hover:text-light-purple-h transition duration-300">
                Careers
              </Link>
              <Link to="/privacy-policy" className="text-lg font-medium hover:text-light-purple-h transition duration-300">
                Privacy Policy
              </Link>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-grow flex items-center justify-center bg-gray-100 p-4">
          <Routes>
            {/* Home Page Route */}
            <Route
              path="/"
              element={
                <div className="text-center">
                  {/* Coming Soon Image */}
                  <div className="mb-8">
                    <img
                      src={ComingSoon}
                      alt="Coming Soon"
                      className="w-11/12 sm:w-8/12 md:w-6/12 lg:w-5/12 mx-auto"
                    />
                  </div>
                </div>
              }
            />
            {/* Energy Page Route */}
            <Route path="/energy" element={<Energy />} />

            {/* Broadband Page Route */}
            <Route path="/broadband" element={<Broadband />} />

            {/* Careers Page Route */}
            <Route path="/careers" element={<Careers />} />

            {/* Privacy Policy Page Route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;





